import React from 'react'

const AuctionIcon = ({ ...rest }) => {
    return (
        <svg
            {...rest}
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 511.999 511.999"
            xmlSpace="preserve"
        >
            <g>
                <g>
                    <path
                        d="M117.776,174.345c-3.9-3.9-10.24-3.9-14.139,0c-3.91,3.91-3.91,10.24,0,14.14c3.9,3.91,10.24,3.91,14.139,0
               C121.685,184.585,121.685,178.255,117.776,174.345z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M253.199,447.718c4.409-5.972,6.792-13.167,6.792-20.732c0-19.298-15.7-34.999-34.999-34.999H54.998
               c-9.353,0-18.142,3.64-24.749,10.249c-6.61,6.608-10.25,15.397-10.25,24.75c0,7.749,2.536,14.915,6.815,20.72
               C10.891,456.078,0,472.778,0,491.983v10c0,5.523,4.478,10,10,10h259.99c5.522,0,10-4.477,10-10v-10
               C279.99,472.787,269.11,456.094,253.199,447.718z M44.39,416.376c2.831-2.832,6.599-4.391,10.608-4.391h169.994
               c8.271,0,14.999,6.729,14.999,14.999c0,4.01-1.56,7.777-4.392,10.609c-2.831,2.832-6.599,4.391-10.608,4.391H54.998
               c-8.271,0-14.999-6.729-14.999-14.999C39.999,422.976,41.558,419.208,44.39,416.376z M19.999,491.983
               c0-16.541,13.458-29.999,29.999-29.999h5h169.994h5c16.541,0,29.999,13.458,29.999,29.999H19.999z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M502.693,420.124c-3.396-5.263-7.448-9.917-12.045-13.833l-46.725-40.753c-0.364-0.548-0.786-1.068-1.269-1.551
               c-0.812-0.811-1.732-1.444-2.71-1.918l-28.257-24.645l-130.79-114.091c5.354-5.597,8.302-12.916,8.302-20.699
               c0-8.018-3.121-15.553-8.794-21.225c-2.474-2.471-5.286-4.414-8.291-5.837l12.1-12.1c3.382,0.899,6.857,1.359,10.335,1.359
               c10.241-0.001,20.483-3.899,28.279-11.695c7.558-7.557,11.72-17.602,11.72-28.285s-4.162-20.728-11.719-28.285L237.981,11.72
               c-7.557-7.557-17.603-11.719-28.289-11.719c-10.681,0-20.724,4.162-28.28,11.719c-10.444,10.444-13.882,25.273-10.336,38.615
               L50.351,171.059c-3.336-0.887-6.807-1.355-10.353-1.355c-10.688,0-20.729,4.159-28.28,11.709
               c-15.596,15.596-15.596,40.973,0,56.57l84.847,84.848c7.799,7.798,18.042,11.697,28.285,11.697
               c10.244,0,20.487-3.899,28.286-11.697c10.444-10.445,13.882-25.273,10.336-38.615l12.075-12.076
               c1.451,3.03,3.42,5.826,5.866,8.271c5.666,5.666,13.199,8.787,21.215,8.787c7.775,0,15.091-2.943,20.696-8.291L362.63,440.961
               c0.384,0.6,0.832,1.171,1.356,1.695c0.179,0.179,0.369,0.342,0.558,0.504l41.511,47.694c1.032,1.218,2.135,2.416,3.28,3.561
               c11.401,11.397,26.46,17.582,42.27,17.582c2.787,0,5.6-0.192,8.417-0.582c18.829-2.606,35.102-13.87,44.644-30.908
               C515.119,461.834,514.341,438.127,502.693,420.124z M195.555,25.862c3.779-3.779,8.801-5.861,14.138-5.861
               c5.344,0,10.369,2.082,14.147,5.861l84.848,84.847c3.779,3.779,5.86,8.802,5.86,14.143c0,5.341-2.081,10.364-5.861,14.143
               c-7.795,7.797-20.481,7.797-28.276,0L195.56,54.144c-0.002-0.002-0.003-0.003-0.005-0.005
               C187.758,46.343,187.758,33.658,195.555,25.862z M138.994,308.689c-7.798,7.798-20.489,7.799-28.287,0L25.86,223.842
               c-7.799-7.799-7.799-20.487,0-28.287c3.772-3.773,8.794-5.851,14.138-5.851c5.343,0,10.362,2.077,14.133,5.847l84.862,84.862
               C146.791,288.208,146.791,300.894,138.994,308.689z M181.096,238.309l-27.961,27.962l-84.856-84.856L181.412,68.28l84.855,84.856
               l-28.012,28.013c-0.091,0.086-0.18,0.17-0.276,0.265l-56.538,56.539C181.324,238.068,181.211,238.188,181.096,238.309z
                M216.802,259.17c-0.02,0.02-0.039,0.039-0.059,0.059l-7.042,7.042c-1.889,1.888-4.4,2.928-7.074,2.928
               c-2.673,0-5.185-1.04-7.073-2.929c-1.891-1.89-2.932-4.404-2.932-7.079c0-2.618,0.999-5.081,2.812-6.939l56.801-56.801
               c3.893-3.786,10.187-3.739,14.034,0.104c1.89,1.89,2.931,4.404,2.931,7.079c0,2.673-1.038,5.18-2.932,7.069l-7.001,7.001
               c-0.048,0.047-0.096,0.095-0.143,0.143L216.802,259.17z M237.51,266.745l29.226-29.226l123.742,107.935l-45.154,45.155
               L237.51,266.745z M358.485,405.731l47.099-47.1l15.107,13.177l-49.045,49.046L358.485,405.731z M487.217,470.737
               c-6.454,11.523-17.365,19.128-29.936,20.868c-12.542,1.736-24.865-2.395-33.807-11.333c-0.769-0.768-1.505-1.569-2.191-2.381
               c-0.032-0.038-0.064-0.076-0.097-0.113l-36.38-41.802l50.991-50.992l41.75,36.417c0.031,0.028,0.063,0.055,0.096,0.083
               c3.125,2.657,5.898,5.848,8.251,9.494C493.456,442.663,493.986,458.643,487.217,470.737z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M188.484,103.636c-3.906-3.905-10.236-3.905-14.142,0l-42.428,42.428c-3.905,3.905-3.905,10.237,0,14.141
               c1.953,1.953,4.512,2.929,7.071,2.929c2.559,0,5.118-0.977,7.071-2.929l42.428-42.428
               C192.389,113.873,192.389,107.542,188.484,103.636z"
                    />
                </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    )
}

export default AuctionIcon
